import { useLayoutStore } from "@store/layoutStore";
import { shallow } from "zustand/shallow";
import Script from "next/script";
import { getCookie } from "cookies-next";

export const RoyalHolidaysWidgetChat = () => {
  const langCode = String(getCookie("lan_code")).split("-")[0];
  const { isEnableRoyalholidayChat, isEnableRoyalholidayChat2 } =
    useLayoutStore(
      (state) => ({
        isEnableRoyalholidayChat:
          state.externalConfig?.isEnableRoyalholidayChat,
        isEnableRoyalholidayChat2:
          state.externalConfig?.isEnableRoyalholidayChat2,
      }),
      shallow
    );

  if (!isEnableRoyalholidayChat) {
    return <></>;
  }
  if (!langCode) {
    return <></>;
  }
  if (isEnableRoyalholidayChat2) {
    return (
      <>
        <Script
          id="zoho-salesiq-config"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
                window.$zoho = window.$zoho || {};
                $zoho.salesiq = $zoho.salesiq || { ready: function() {
                $zoho.salesiq.language("${langCode}");
                } };
              `,
          }}
        />
        <Script
          id="zsiqscript"
          strategy="lazyOnload"
          src="https://salesiq.zohopublic.com/widget?wc=siq50f6fd50c651ab28a2f59ee55e64987f5465ed6f231a2f836b4bbe1932e10eb6727d13e55314baea4a1d29388b6aacd1"
          defer
        />
      </>
    );
  }
  return (
    <>
      <div id="travelsociety-widget-ts">
        <Script
          id="travelsociety-widget-container"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
             var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq  || {widgetcode: "siq50f6fd50c651ab28a2f59ee55e64987f5465ed6f231a2f836b4bbe1932e10eb6727d13e55314baea4a1d29388b6aacd1", values:{},ready:function(){$zoho.salesiq.language("${langCode}") }};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);
              `,
          }}
        />
      </div>
    </>
  );
};
